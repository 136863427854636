<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <v-card-title class="headline">Promotion resources for {{event.name}}</v-card-title>

      <v-card-text>      
        <p>
          View and download various promotional resources to easily announce your event and help participants to join.
          Please make sure to verify the correctness of these resources for your specific configuration before sending them out.
        </p>
      </v-card-text>

      <v-alert v-if="!eventUtil.isPublished()" type="info" tile>
        Please publish this event first.
      </v-alert>
      <v-alert v-else-if="!eventUtil.isProPlan() || !eventUtil.hasPaidSupportPlan()" type="info" tile>
        <div class="message-plus-button">
          <p><router-link class="white--text" :to="{name: 'eventmanagerLicense', params: {id:event.id}}">Upgrade to a PRO plan</router-link> and ensure you have an <router-link class="white--text" :to="{name: 'eventmanagerLicense', params: {id:event.id}}">active support plan</router-link> to access these resources.</p>
          <v-btn class="shrink white--text" :to="{name: 'eventmanagerLicense', params: {id:event.id}}">Upgrade</v-btn>
        </div>
      </v-alert>
      <!-- <v-alert v-else-if="!eventUtil.hasPaidSupportPlan()" type="info" tile>
        <div class="d-flex flex-row align-content-center">
          <p class="grow mb-0"><router-link class="white--text" :to="{name: 'eventmanagerLicense', params: {id:event.id}}">Purchase a support plan</router-link> to access these resources.</p>
          <v-btn class="shrink white--text" :to="{name: 'eventmanagerLicense', params: {id:event.id}}">Upgrade</v-btn>
        </div>
      </v-alert> -->

      <v-card-title class="subheader">Announcing your event</v-card-title>
      <v-row class="mx-2"> 
        <v-col cols="12" sm="4">     
          <BigButton :disabled="!isAvailable" title="Poster" text="Download a printable poster which includes a QR code." icon="fa-file-pdf" elevated @click="openPdf('poster')"/>
        </v-col>
        <v-col cols="12" sm="4">     
          <BigButton :disabled="!isAvailable" title="Banner" text="Image to use in emails and internal comms." icon="fa-file-image" elevated @click="openImage('banner')"/>
        </v-col>
      </v-row> 
      <v-row class="mx-2"> 
        <v-col cols="12" sm="4">     
          <BigButton :disabled="!isAvailable" title="Announcement Email" text="Introduce the event and explain onboarding process." icon="fa-envelope" elevated @click="openEmail('announcement')" />
        </v-col>
        <v-col cols="12" sm="4">     
          <BigButton :disabled="!isAvailable" title="Invite Email" text="Explains how people can join and the rules of the challenge." icon="fa-envelope" elevated @click="openEmail('invite')" />
        </v-col>
      </v-row> 
      <v-card-title class="subheader">Event lifecycle</v-card-title>
      <v-row class="mx-2"> 
        <v-col cols="12" sm="4">     
          <BigButton :disabled="!isAvailable" title="Start" text="For the first day of the event." icon="fa-envelope" elevated @click="openEmail('start')" />
        </v-col>
        <v-col cols="12" sm="4">     
          <BigButton :disabled="!isAvailable" title="Midway Motivation" text="Mid-Challenge Motivation: You've Got This!" icon="fa-envelope" elevated @click="openEmail('midway')" />
        </v-col>
        <v-col cols="12" sm="4">     
          <BigButton :disabled="!isAvailable" title="Last Call for Data" text="Ending Soon - Sync your data!" icon="fa-envelope" elevated @click="openEmail('last-call')" />
        </v-col>
        <v-col cols="12" sm="4">     
          <BigButton :disabled="!isAvailable" title="Wrap-up &amp; reflections" text="To send on the last day of the event." icon="fa-envelope" elevated @click="openEmail('wrap-up')" />
        </v-col>
      </v-row>
      <v-card-title class="subheader">How-to guides</v-card-title>
      <v-card-text>Share these how-to guides with the participants to help them get started quickly!</v-card-text>
      <EventTutorials :event="event" />

    </v-card>


    <v-dialog v-if="emailTemplate" v-model="showEmailTemplateDialog" max-width="600">
      <v-card>
        <v-card-title>Email template</v-card-title>
        <v-card-text>
          <p>Here is a template email you can use in your communication plan. We advise to send out the emails through your own internal communication channels for optimal deliverability.</p>
          <p>This template might contain some conditional or optional sections within <strong>[square brackets]</strong>. Please make sure to select the correct version before sending the email out.</p>
        </v-card-text>
        <v-card-text>
          <h4 class="d-flex">
            Subject
            <v-spacer />
            <v-btn
              text
              small
              color="primary"
              class="ma-0"
              @click="$helpers.copyToClipboard(emailTemplate.subject, $toast);">
              <v-icon class="me-1" x-small>fa-copy</v-icon>
              Copy
            </v-btn>
          </h4>
          <vue-markdown class="markdown outlined mb-4" :html="false" :source="emailTemplate.subject" />
          <!-- <v-text-field 
            v-model="emailTemplate.subject" 
            readonly
            disabled
            class="mb-4 mt-n4"
            /> -->

          <h4 class="d-flex">
            Body
            <v-spacer />
            <v-btn
              text
              small
              color="primary"
              class="ma-0"
              @click="$helpers.copyToClipboard(emailTemplate.body, $toast);">
              <v-icon class="me-1" x-small>fa-copy</v-icon>
              Copy
            </v-btn>
          </h4>
          <vue-markdown class="markdown outlined mb-4" :html="false" :source="emailTemplate.body" />
        </v-card-text>
        <v-card-actions>
          <v-btn outlined color="primary" @click="sendEmailTemplate(emailTemplate)" :loading="$store.getters.isLoading"><v-icon class="me-1" small>fa-envelope</v-icon>Email to me</v-btn>
          <v-spacer/>
          <v-btn text color="primary" @click="showEmailTemplateDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showPdfTemplateDialog" max-width="600">
      <v-card>
        <v-card-title>PDF template</v-card-title>
        <v-card-text>
          <p>Here is a template pdf you can use in your communication strategy. Please make sure to verify the correctness of this template for your specific configuration.</p>
        </v-card-text>
        <v-card-text v-if="!pdfResponse" class="mt-4 pb-0 px-2" style="text-align:center;">
          <v-progress-circular indeterminate :size="100" :width="5" color="primary" class="mx-a"></v-progress-circular>
        </v-card-text>
        <v-card-text v-else-if="pdfResponse.status === 'OK'">
          <v-alert type="success">
            <div class="message-plus-button">
              <p>Your file is ready.</p>
              <v-btn :href="pdfResponse.id" download>Download</v-btn>
            </div>
          </v-alert>
          <p>Want to make changes to the generated document? You can open the pdf in Word to customize it.</p>
        </v-card-text>
        <v-card-text v-else>
          <v-alert type="info">
            <p>{{ pdfResponse.msg }}</p>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="pdfResponse && pdfResponse.status === 'OK'" outlined color="primary" @click="openPdf(selectedAssetType, true)" :loading="$store.getters.isLoading"><v-icon class="me-1" small>fa-sync-alt</v-icon>Regenerate</v-btn>
          <v-spacer/>
          <v-btn text color="primary" @click="showPdfTemplateDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showImageTemplateDialog" max-width="600">
      <v-card>
        <v-card-title>Image template</v-card-title>
        <v-card-text>
          <p>Here is a image you can use in your communication strategy. Please make sure to verify the correctness of this template for your specific configuration.</p>
        </v-card-text>
        <v-card-text v-if="!imageResponse" class="mt-4 pb-0 px-2" style="text-align:center;">
          <v-progress-circular indeterminate :size="100" :width="5" color="primary" class="mx-a"></v-progress-circular>
        </v-card-text>
        <v-card-text v-else-if="imageResponse.status === 'OK'">
          <v-alert type="success">
            <div class="message-plus-button">
              <p>Your image is ready.</p>
              <v-btn :href="imageResponse.id" download>Download</v-btn>
            </div>
          </v-alert>
          <img :src="imageResponse.id" style="max-width:100%;" />            
        </v-card-text>
        <v-card-text v-else>
          <v-alert type="info">
            <p>{{ imageResponse.msg }}</p>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="imageResponse && imageResponse.status === 'OK'" outlined color="primary" @click="openImage(selectedAssetType, true)" :loading="$store.getters.isLoading"><v-icon class="me-1" small>fa-sync-alt</v-icon>Regenerate</v-btn>
          <v-spacer/>
          <v-btn text color="primary" @click="showImageTemplateDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import EventUtil from "@/util/eventUtil";
import EventTutorials from '@/components/EventTutorials.vue';
import BigButton from "@/components/BigButton.vue";
import VueMarkdown from '@/components/VueMarkdown.vue'

export default {
  name: "Reports",
  components: {
    BigButton,
    VueMarkdown,
    EventTutorials,
  },
  props: {
    event: Object,
  },
  data() {
    return {
      provider: null,
      model: {},
      stats: null,
      siteData: siteData,
      emailTemplate: null,
      selectedAssetType: null,
      showEmailTemplateDialog: false,
      showPdfTemplateDialog: false,
      pdfResponse: null,
      showImageTemplateDialog: false,
      imageResponse: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getEvent(id) {
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

    async openEmail(type) {
      this.emailTemplate = (await eventManagerService.getPromoEmailTemplate(this.event.id, type)).data;
      /*this.emailTemplate = {
        subject: 'Test tralalal',
        body: 'Hi everyone\r\ntralalallala\r\n\r\nEtc.'
      };*/
      this.selectedAssetType = type;
      this.showEmailTemplateDialog = true;
    },
    async sendEmailTemplate() {
      var response = (await eventManagerService.sendPromoEmailTemplate(this.event.id, this.selectedAssetType)).data;
      this.$helpers.toastResponse(this, response, 'Email is sent, please check your mailbox in a few minutes.');
      if (response.status === 'OK') {
        this.showEmailTemplateDialog = false;
      }
    },

    async openPdf(type, force) {
      this.pdfResponse = null;
      this.showPdfTemplateDialog = true;
      this.selectedAssetType = type;
      this.pdfResponse = (await eventManagerService.generatePromoPdfTemplate(this.event.id, type, force)).data;
    },

    async openImage(type, force) {
      this.imageResponse = null;
      this.showImageTemplateDialog = true;
      this.selectedAssetType = type;
      this.imageResponse = (await eventManagerService.generatePromoImageTemplate(this.event.id, type, force)).data;
    },
    
  },
  computed: {
    isAvailable() {
      return this.eventUtil.isPublished() && this.eventUtil.isProPlan() && this.eventUtil.hasPaidSupportPlan();
    },
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: `Promotional Resources`, disabled: true },
      ];
    },
    eventUtil() {
      return new EventUtil(this, this.event);
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
.outlined {
  max-height: 300px;
  overflow-y: auto;
  border: dotted grey 1px;
  padding: 10px;
  background: #f5f5f5;
}
</style>

